

















import Vue from "vue";
import userManager from "@/services/userManager";
import StatisticsPage from "@/components/StatisticsPage.vue";

export default Vue.extend({
  components: { StatisticsPage },
  data() {
    return {
      userManager,
      fields: {
        pgCount: "Số đơn",
        pgCountPerTotal: "%Số đơn / Tổng đơn",
        pgPcs: "PCS",
        pgPcsPerTotal: "%PCS / Tổng PCS",
        pgRevenue: "DSDK MKT",
        pgRevenuePerTotal: "%DSDK MKT / Tổng DSDK MKT",
        pgCost: "Cost",
        pgCostPerRevenue: "%Cost / DSDK MKT",
        pgRealRevenue: "DSDK MKT thực",
        pgCostPerRealRevenue: "%Cost / DSDK MKT thực",
        pgCanceledDtCount: "Số đơn hủy đơn trùng",
        pgCanceledDtCountPerTotal: "%Số đơn hủy đơn trùng / Tổng đơn + Đơn hủy",
        pgCanceledICount: "Số đơn hủy I",
        pgCanceledICountPerTotal: "%Số đơn hủy I / Tổng đơn + Đơn hủy",
        pgCanceledTelesaleCount: "Số đơn hủy Telesale",
        pgCanceledTelesaleCountPerTotal: "%Số đơn hủy Telesale / Tổng đơn + Đơn hủy",
      },
    };
  },
});
